import React from "react";
import {Button, Dropdown, Menu, Space} from "antd";

import {TagType} from "../../../../constants/constants";
import {DownOutlined} from "@ant-design/icons";
import CreateTag from "../../../Common/QuickCreate/Tag";
import MergeTag from "../Merge/Merge";
import {useState} from "react";
import {setSelectedTagType} from "../../../../redux/reducers/settings";
import {useDispatch} from "react-redux";

const SettingsRowButtons = () => {
    const dispatch = useDispatch();
    const [tagType, setTagType] = useState(null);
    const [isMergeVisible, setMergeVisible] = useState(false);

    const handleNewTagClick = (item) => {
        const tagType = parseInt(item.key, 10);
        setTagType(tagType);
        dispatch(setSelectedTagType(tagType));
    };

    const NewContactMenu = (
        <Menu onClick={handleNewTagClick}>
            <Menu.Item key={TagType.Category}>Category</Menu.Item>
            <Menu.Item key={TagType.Department}>Department</Menu.Item>
            <Menu.Item key={TagType.Group}>Group</Menu.Item>
            <Menu.Item key={TagType.ItemKeyword}>Item Keyword</Menu.Item>
            <Menu.Item key={TagType.ItemType}>Item Type</Menu.Item>
            <Menu.Item key={TagType.Role}>Role</Menu.Item>
            <Menu.Item key={TagType.TaskStatus}>Status</Menu.Item>
        </Menu>
    );

    return (
        <Space wrap>
            <Button onClick={() => setMergeVisible(true)}>Merge</Button>
            <Dropdown trigger="click" overlay={NewContactMenu}>
                <Button type="primary">
                    New Tag <DownOutlined/>
                </Button>
            </Dropdown>

            <CreateTag tagType={tagType} onClose={() => setTagType(null)}></CreateTag>
            <MergeTag visible={isMergeVisible} onClose={() => setMergeVisible(false)}></MergeTag>
        </Space>
    );
};

export default SettingsRowButtons;
