import React from "react";
import CommonSearchFilter from "../../../../Common/Tables/FilterBar/SearchFilter";

const fieldsToSearch = [
    "Name",
    "Description",
    (item) => item.DepartmentTag?.Name,
    (item) => item.Size,
    (item) => item.Sources?.map((s) => s.Name),
    (item) => item.ItemTypeTags?.map((x) => x.Name),
    "Size",
    (item) => item.ItemKeywordTags?.map((x) => x.Name)
];
const SearchFilter = ({setFilterHandler, needResetFilter}) => (
    <CommonSearchFilter setFilterHandler={setFilterHandler} needResetFilter={needResetFilter}
                        fieldsToSearch={fieldsToSearch}/>);

export default SearchFilter;
