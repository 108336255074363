import React, {useState} from "react";
import {Button} from "antd";
import {SelectWithFormItem} from "../Input";
import CreateTag from "../QuickCreate/Tag";
import {createColorTagRendered} from "../../../helpers/Tags";
import {TagType} from "../../../constants";
import _ from "lodash";

const tagButtonText = {
    [TagType.Role]: "+ Add a Role",
    [TagType.Group]: "+ Add a Group",
    [TagType.ItemKeyword]: "+ Add a Item Keyword",
    [TagType.ItemType]: "+ Add a Item Type",
    [TagType.Department]: "+ Add a Department",
    [TagType.Category]: "+ Add a Category",
};

const tagColor = {
    [TagType.Role]: "red",
    [TagType.Group]: "purple",
    [TagType.ItemKeyword]: "blue",
    [TagType.ItemType]: "yellow",
    [TagType.Department]: "green",
    [TagType.Category]: "orange",
};

const TagsSelect = ({
                        form, name, label, save = () => {
    }, disabled, options, groups, placeholder, tagType, getValueProps, closeOnChange
                    }) => {
    const [createTagType, setCreateTagType] = useState(null);
    const [searchValue, setSearchValue] = useState("");

    const onTagCreated = (newTag) => {
        let tags = form.getFieldValue(name) || [];
        if (_.isNumber(tags)) {
            tags = [tags.toString()];
        }
        form.setFieldsValue({
            [name]: [...tags, newTag.AccountTagId.toString()],
        });
        save();
    };

    const handleChange = (value) => {
        save(value);
        setSearchValue("");
    };

    return (
        <>
            <SelectWithFormItem
                allowClear
                searchValue={searchValue}
                disabled={disabled}
                label={label}
                name={name}
                mode="multiple"
                tagRender={createColorTagRendered(tagColor[tagType], true)}
                style={{width: "100%"}}
                placeholder={placeholder}
                options={options}
                onChanged={handleChange}
                onSearch={setSearchValue}
                closeOnChange={closeOnChange}
                getValueProps={getValueProps}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <div className="btn-quick-create">
                            <Button type="dashed" onClick={() => setCreateTagType(tagType)}>
                                {tagButtonText[tagType]}
                            </Button>
                        </div>
                    </>
                )}>
                {groups}
            </SelectWithFormItem>
            <CreateTag
                defaultName={searchValue}
                tagType={createTagType}
                onCreated={onTagCreated}
                onClose={() => setCreateTagType(null)}></CreateTag>
        </>
    );
};

export default TagsSelect;