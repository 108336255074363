import React, {useState, useEffect, useCallback, useMemo} from "react";
import _ from "lodash";
import MultipleFilterWithSearchFilter from "../../../../Common/Tables/FilterBar/MultipleFilterWithSearchFilter";

const noItem = {
    value: "noItem", title: "— No Departments —", sortRank: 1,
};

function getDepartments(data) {
    if (!data || data.length === 0) {
        return [noItem];
    }

    return [noItem, ..._.chain(data)
        .map((d) => d.DepartmentTag)
        .filter((tag) => tag)
        .uniqBy((x) => x.AccountTagId)
        .map((item) => ({value: item.AccountTagId, title: item.Name}))
        .value()];
}

const DepartmentsFilter = ({data, needResetFilter, setFilterHandler}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const items = useMemo(() => {
        return getDepartments(data);
    }, [data]);

    const rolesHash = useMemo(() => items.map((i) => i.value).join(""), [items]);

    const reset = useCallback(() => {
        setSelectedItems(items);
    }, [rolesHash]);

    useEffect(() => {
        if (needResetFilter) {
            reset();
        }
    }, [needResetFilter, reset]);

    useEffect(() => {
        onChanged(items);
    }, [rolesHash]);

    function onChanged(selectedItems) {
        const filterValues = selectedItems.map((i) => i.value);
        const isNoItemSelected = selectedItems.find((i) => i.value === noItem.value);

        setFilterHandler((item) => filterValues.includes(item.DepartmentTag?.AccountTagId) ||
            (isNoItemSelected && !item.DepartmentTag));

        setSelectedItems(selectedItems);
    }

    return (<MultipleFilterWithSearchFilter
        selectedItems={selectedItems}
        items={items}
        allItemsText="All Departments"
        onChanged={onChanged}
        search
    />);
};

export default DepartmentsFilter;