import {DownOutlined} from "@ant-design/icons";
import {Button, Dropdown, Menu} from "antd";
import React from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {TagType} from "../../../../../constants/constants";
import {setSelected} from "../../../../../redux/reducers/mainTable";
import {setSelectedTagType} from "../../../../../redux/reducers/settings";
import "./TagTypeFilter.scss";

const items = [
    {
        value: TagType.Category,
        title: "Categories",
    },
    {
        value: TagType.Department,
        title: "Departments",
    },
    {
        value: TagType.ItemKeyword,
        title: "Item Keywords",
    },
    {
        value: TagType.ItemType,
        title: "Item Types",
    },
    {
        value: TagType.Group,
        title: "Groups",
    },
    {
        value: TagType.Role,
        title: "Roles",
    },
    {
        value: TagType.TaskStatus,
        title: "Status",
    },
];

const TagTypeFilter = () => {
    const selectedTagType = useSelector((state) => state.settings.selectedTagType);
    const dispatch = useDispatch();

    const onSelected = (item) => {
        dispatch(setSelectedTagType(parseInt(item.key, 10)));
        dispatch(setSelected([]));
    };

    const TagTypeFilterMenu = () => {
        return (
            <Menu onClick={onSelected}>
                {items.map((item) => (
                    <Menu.Item key={item.value}>{item.title}</Menu.Item>
                ))}
            </Menu>
        );
    };

    return (
        <Dropdown trigger="click" overlay={TagTypeFilterMenu}>
            <Button className="tags-filter" type="link">
                {items.find((item) => item.value === selectedTagType).title}
                <DownOutlined className="filter-text-down"/>
            </Button>
        </Dropdown>
    );
};

export default TagTypeFilter;
