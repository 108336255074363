import React from "react";
import SearchFilter from "./SearchFilter";
import Limits from "./Limits";
import FilterBar from "../../Common/Tables/FilterBar/FilterBar";

const SettingsFilterBar = ({...props}) => {
    return (
        <FilterBar
            {...props}
            filterComponents={[Limits, SearchFilter]}
        />
    );
};

export default SettingsFilterBar;
