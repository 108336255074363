import React, {useState, useEffect, useRef} from "react";
import {DatePicker, Row, Col, Button, TimePicker, Form} from "antd";
import "./DateAndTime.scss";
import moment from "moment";
import _ from "lodash";
import {useSelector} from "react-redux";

const {RangePicker} = DatePicker;

const dateApiFormat = "YYYY-MM-DD";
const dateTimeApiFormat = "YYYY-MM-DDThh:mm:ss";
const timeApiFormat = "HH:mm:ss";

const toMoment = (val, format) => {
    const m = moment(val, format);
    return m.isValid() ? m : null;
};

const formatDates = (val, format) => (moment.isMoment(val) ? val.format(format) : val);

export const getDatesForApi = (datesDto) => ({
    startDate: formatDates(datesDto.startDate, dateApiFormat),
    endDate: formatDates(datesDto.endDate, dateApiFormat),
    startTime: formatDates(datesDto.startTime, timeApiFormat),
    endTime: formatDates(datesDto.endTime, timeApiFormat),
});

const DateAndTime = ({
                         dateFormat,
                         timeFormat,
                         isDisabled,
                         isAllDay,
                         startDate,
                         endDate,
                         isTimeOnly,
                         onChange,
                         inputClassName,
                     }) => {

    const [dateValue, setDateValue] = useState([]);
    const [timeValue, setTimeValue] = useState([]);

    useEffect(() => {
        if (isAllDay) {
            setTimeValue([]);
        } else {
            setTimeValue([toMoment(startDate, dateTimeApiFormat), toMoment(endDate, dateTimeApiFormat)]);
        }
        if (isTimeOnly) {
            setDateValue([]);
        } else {
            setDateValue([toMoment(startDate, dateApiFormat), toMoment(endDate, dateApiFormat)]);
        }
    }, [startDate, endDate, isAllDay, isTimeOnly]);

    const getDdo = () => ({
        startDate: dateValue[0],
        endDate: dateValue[1],
        startTime: timeValue[0],
        endTime: timeValue[1],
    });

    const onDateChange = (val) => {
        val = val || [null, null];
        setDateValue(val);
        onChange({
            ...getDdo(),
            startDate: val[0],
            endDate: val[1],
        });
    };

    const onTimeChange = (val) => {
        val = val || [null, null];
        setTimeValue(val);
        onChange({
            ...getDdo(),
            startTime: val[0],
            endTime: val[1],
        });
    };

    return (
        <>
            <Form.Item label="Date" className="ant-form-item-without-validation">
                <RangePicker
                    className={inputClassName}
                    disabled={isDisabled}
                    onChange={onDateChange}
                    value={dateValue}
                    placeholder={["Start date", "End date"]}
                    format={dateFormat}
                    showTime={false}></RangePicker>
            </Form.Item>
            <Form.Item name="TimeRange" label="Time" className="ant-form-item-without-validation">
                <TimeRangeWithPresets
                    timeFormat={timeFormat}
                    isDisabled={isDisabled}
                    timeValue={timeValue}
                    setTimeValue={setTimeValue}
                    onChange={onTimeChange}
                    inputClassName={inputClassName}>
                </TimeRangeWithPresets>
            </Form.Item>
        </>
    );
};

const validateTimeRange = (times) => {
    return _.isArray(times) && times[0]?.isValid() && times[1]?.isValid();
};

export const TimeRangeWithPresets = ({
                                         timeFormat,
                                         isDisabled,
                                         timeValue,
                                         setTimeValue,
                                         onChange,
                                         inputClassName
                                     }) => {
    const timeRef = useRef();
    const [timeValueInternal, setTimeValueInternal] = useState([]);
    const timeShortcuts = useSelector((state) => state.durationShortcuts.shortcuts);

    const intervals = timeShortcuts.map(shortcut => {
        const minutes = shortcut.Value * 60;
        const label = minutes >= 60 ? `${minutes / 60}h` : `${minutes}min`;
        return {minutes, label};
    });

    const setTimeValueProxy = (value, addMinutesL) => {
        let [start, end] = value || [];

        if (!(start && start.isValid())) return;
        if (!(end && end.isValid()) && addMinutesL == null) return;

        if (addMinutesL != null && start) {
            end = start.clone().add(addMinutesL, "minutes");
        }

        if (addMinutesL === 0) {
            end = start.clone();
        }

        setTimeValueInternal([start, end]);

        if (validateTimeRange([start, end]))
            if (setTimeValue) {
                setTimeValue([start, end]);
            }
        onChange([start, end]);
    };

    useEffect(() => {
        if (timeValue) {
            setTimeValueInternal(timeValue);
        }
    }, [timeValue]);

    const onCalendarChange = (val, stringDate, {range}) => {
        val = val || [null, null];

        if (range === "end") {
            if (stringDate[0] === "" && stringDate[1] === "") {
                clearTime();
                return;
            }
            timeRef.current.blur();
            setTimeout(() => {
                timeRef.current.blur();
            }, 10);
        }
    };

    const clearTime = () => {
        setTimeValueInternal([null, null]);
        if (setTimeValue) {
            setTimeValue([null, null]);
        }
        onChange([null, null]);
    };

    let addMinutes;
    let startDateSelected;
    let endDateSelected;

    const onTimeCellClick = () => {
        setTimeout(() => {
            const startString = document.body.querySelector(`input[placeholder='Start time']`)?.value;
            const endString = document.body.querySelector(`input[placeholder='End time']`)?.value;
            const start = moment(startString, [moment.ISO_8601, timeFormat]);
            const end = moment(endString, [moment.ISO_8601, timeFormat]);
            startDateSelected = start.isValid() ? start : null;
            endDateSelected = end.isValid() ? end : null;
        }, 50);
    };

    const onBlur = (e) => {
        const startDateSelectedL = startDateSelected;
        const endDateSelectedL = endDateSelected;
        const addMinutesL = addMinutes;

        startDateSelected = null;
        endDateSelected = null;
        addMinutes = null;

        if (startDateSelectedL && (endDateSelectedL || addMinutesL)) {
            setTimeValueProxy([startDateSelectedL, endDateSelectedL], addMinutesL);
            return;
        }

        const time = moment(e.target.value, [moment.ISO_8601, timeFormat]);
        if (!time.isValid()) return;

        let [start, end] = [];
        if (e.target.placeholder === "Start time") {
            [start, end] = [time, timeValueInternal[1]];
        }
        if (e.target.placeholder === "End time") {
            [start, end] = [timeValueInternal[0], time];
        }

        setTimeValueProxy([start, end], addMinutesL);
    };

    const rangePickerFooter = () => {
        const rows = [];
        for (let i = 0; i < intervals.length; i += 4) {
            rows.push(intervals.slice(i, i + 4));
        }

        return (
            <>
                {rows.map((row, rowIndex) => (
                    <Row key={rowIndex}>
                        {row.map((item, colIndex) => (
                            <Col key={colIndex} className="interval-buttons">
                                <Button
                                    size="small"
                                    type="link"
                                    onClick={() => {
                                        addMinutes = item.minutes;
                                        timeRef.current.blur();
                                    }}>
                                    {item.label}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                ))}
            </>
        );
    };

    return (
        <TimePicker.RangePicker
            ref={timeRef}
            order={false}
            className={inputClassName}
            disabled={isDisabled}
            value={timeValueInternal}
            onChange={(val) => setTimeValueProxy(val)}
            onCalendarChange={onCalendarChange}
            onBlur={onBlur}
            panelRender={(panel) => {
                return (
                    <div onClick={onTimeCellClick}>
                        {panel.props.children[0]} {panel.props.children[1]}
                    </div>
                );
            }}
            popupClassName="time-range-picker"
            format={timeFormat}
            placeholder={["Start time", "End time"]}
            renderExtraFooter={rangePickerFooter}
        ></TimePicker.RangePicker>
    );
};

export default DateAndTime;