import React from "react";
import _ from "lodash";
import {Tag, Select, Badge} from "antd";
import {ApiProjectType} from "../constants/constants";
import {CheckOutlined, QuestionOutlined, DollarCircleFilled, PlusOutlined, CloseOutlined} from "@ant-design/icons";

export const Various = "Various";

export const mapTagToOption = (tag) => ({value: tag.AccountTagId.toString(), label: tag.Name || " "});
export const mapTextToOption = (tag) => ({value: tag, label: tag});

export function getTags(selected, key) {
    const allIds = selected.map((i) => (i[key] || []).filter((x) => x.AccountTagId !== null).map((x) => x.AccountTagId.toString()));
    const tags = _.intersection(...allIds);
    const variousTags = _.difference(_.union(...allIds), tags);

    if (variousTags.length > 0) {
        tags.push(Various);
    }

    return tags;
}

export function getProjectTags(selected, AllProjectOption) {
    const allProjectsSelected = selected.filter((i) => i.IsAllProjects).length === selected.length;
    const tags = _.intersection(...selected.map((i) => i.Projects.map((p) => p.Id.toString())));
    const variousTags = _.xor(...selected.map((i) => i.Projects.map((p) => p.Id.toString())));

    if (allProjectsSelected) {
        return [AllProjectOption.value];
    }

    if (variousTags.length > 0) {
        tags.push(Various);
    }

    return tags;
}

export const hasVarious = (values) => (values || []).includes(Various);
export const withoutVarious = (values) => _.without(values || [], Various).map((t) => parseInt(t, 10));

export const getTagsPayload = (tags = [], isUpdated) => ({
    tags: tags.filter((tag) => tag !== Various).map((t) => parseInt(t, 10)),
    isUpdated,
    updateOnlyCommon: tags.includes(Various),
});

export const createColorTagRendered =
    (color, closable) =>
        ({label, onClose}) =>
            (
                <Tag color={color} closable={closable} onClose={onClose}>
                    {label}
                </Tag>
            );

export const createProjectColorTagRendered =
    (projects, closable) =>
        ({label, onClose, value}) => {
            const projectId = parseInt(value, 10);
            const project = projects.find((p) => p.value === value || p.Id === projectId);
            const color = project?.color || project?.Color;

            return (
                <Tag className="project_tag" color={color} closable={closable} onClose={onClose}>
                    {label}
                </Tag>
            );
        };

export const geTagsGroup = (label = "", tags = []) => {
    return tags.length > 0 && (
        <Select.OptGroup label={label} key={label}>
            {tags.map(({Name, AccountTagId}) => (
                <Select.Option key={AccountTagId} value={AccountTagId.toString()} label={Name || " "}>
                    {Name || " "}
                </Select.Option>
            ))}
        </Select.OptGroup>
    );
};
export const mapValueToOption = (value) => ({value, title: value});

export const getProjectLabel = (project, maxLen = -1) => {
    const name = maxLen > 0 && project.Name?.length > maxLen + 3 ? project.Name.substring(0, maxLen) + "..." : project.Name;

    switch (project.State) {
        case ApiProjectType.Archive:
            return `${name} (archived)`;
        default:
            return name;
    }
};

export const mapProjectWithBadgeToTag = (project) => ({
    value: project.Id.toString(),
    label: (
        <>
            {getProjectLabel(project)}
            {project.State === ApiProjectType.Inactive && (
                <Badge className="project-status-badge project-status-badge-selector" count="Draft"></Badge>
            )}
        </>
    ),
    title: getProjectLabel(project),
    color: project.Color,
    disabled: project.State === ApiProjectType.Archive,
});

export const mapProjectWithBadge = (project) => ({
    value: project.Id,
    postfixIcon:
        project.State === ApiProjectType.Inactive ? (
            <Badge className="project-status-badge project-status-badge-selector" count="Draft"></Badge>
        ) : null,
    title: getProjectLabel(project),
    label: (
        <>
            {getProjectLabel(project)}
            {project.State === ApiProjectType.Inactive && (
                <Badge className="project-status-badge project-status-badge-selector" count="Draft"></Badge>
            )}
        </>
    ),
});

const AllowedTagIconNames = ["CheckOutlined", "QuestionOutlined", "DollarCircleFilled", "PlusOutlined", "CloseOutlined"];

const AllowedTagIcons = {
    QuestionOutlined: <QuestionOutlined/>,
    CheckOutlined: <CheckOutlined/>,
    DollarCircleFilled: <DollarCircleFilled/>,
    PlusOutlined: <PlusOutlined/>,
    CloseOutlined: <CloseOutlined/>,
};

export const iconOptions = AllowedTagIconNames.map((icon) => ({
    value: icon,
    label: AllowedTagIcons[icon],
}));

export const TagIcon = ({icon}) => {
    var iconComponent = AllowedTagIcons[icon];
    return (
        <>
            {iconComponent && iconComponent}
            {!iconComponent && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
        </>
    );
};
